<template>
  <div class="nav-bar font-14">
    <span class="home-icon" @click="gotPrePage"></span>
    <span v-for="(value, index) in navList" :key="index">
      <span :class="{ hoverClass: value.url }" @click="gotoLevel(value.url)">&gt;{{ value.word }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      require: true,
    },
    type:{
      type:String,
      default:''
    }
  },
  methods: {
    gotoLevel(url) {
      if (!url) {
        return ;
      }
      this.$router.push(url);
    },
    gotPrePage() {
      if(!this.type){
        this.$router.go(-1);
      }else{
        this.$router.push({ path: "/ZSmachining" });
      }
    },
  },
};
</script>

<style scoped>
.font-14 {
  font-size: 14px;
}
.nav-bar span {
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(118, 118, 118, 1);
}
.nav-bar span:not(:first-of-type),
.nav-bar span > span {
  margin-left: 5px;
}
.home-icon {
  background: url(../../assets/icon/icon_host18.png) no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.hoverClass:hover {
  cursor: pointer;
  color: red;
}
</style>
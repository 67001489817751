<template>
  <!-- 24小时热榜 前10 -->
  <div class="kr-hotlist">
    <div class="kr-column-title">
      <span class="kr-column-title-icon"></span>
      <span class="kr-column-title-text weight-bold">快讯热榜</span>
      <a
        class="go-more"

        target="_blank"
        rel="noopener noreferrer"
        ></a
      >
    </div>
    <!-- hot 前十 -->
    <div class="hotlist-main">
      <ul>
        <li id="hotListNews" v-for="(item,index) in list" :key="index">
          <a>
            <span class="hot" :class="[index+1 ==1 && 'hot1',index+1 ==2 && 'hot2',index+1 ==3 && 'hot3',index+1 >3 && 'hot4-10']">{{index+1}} </span>
            <span @click="goToNewsFlashDetails(item.id,index,item.hotNum)" id="hot-title" class="hot-title"> {{item.title}}</span>
            <span class="hot-hotNum"><span :class="[index+1<4?'hot-active':'hot-no-active']"> </span>{{item.hotNum>9999?"1万+":item.hotNum}}</span>
          </a>
        </li>
      </ul> 
    </div>

  </div>
</template>

<script>
import {updateHotnumFn} from '@/js/common/index' ;
import { from } from 'apollo-link'
export default {
  data(){
    return{
      nextNewsFlashID: ''
    }
  },
  props: {
    list: {
      type: Array,
      required: true,
    }
  },
  methods:{
    // 24小时热榜跳转到快讯详情页
    goToNewsFlashDetails(id,index,hotNum){
      // console.log(id,index)
      updateHotnumFn(id,hotNum)
      if(index+1 < this.list.length){
        this.nextNewsFlashID = this.list[index+1].id
      }else{
        this.nextNewsFlashID = this.list[0].id
      }
      // console.log(this.nextNewsFlashID)
      this.$router.push({
        path: '/newsFlashDetail',
        query:{
          id: id,
          lowerid: this.nextNewsFlashID,
        },
      })
    }
  }
  // computed: {
  //   frontTwoList: function() {
  //     if (this.list.length <= 2) {
  //       return this.list;
  //     }
  //     return this.list.slice(0, 2);
  //   },
  //   afterTwoList: function() {
  //     if (this.list.length <= 2) {
  //       return [];
  //     }
  //     return this.list.slice(2, this.list.length);
  //   },
  // },
  // props:{
  //     list:{
  //         type:Array,
  //         required:true
  //     }
  // }
}
</script>

<style scoped>
.kr-column-title {
  height: 28px;
  line-height: 28px;
  text-align: left;
}
.kr-column-title .kr-column-title-icon {
  display: inline-block;
  padding: 7px;
  background: url(//static.36krcdn.com/36kr-web/static/home.d1523964.png)
    no-repeat;
  background-size: 246px 1012px;
  background-position: 0 -125px;
}
.kr-column-title .kr-column-title-text {
  font-size: 20px;
  color: #262626;
  padding-left: 4px;
}
.weight-bold {
  font-weight: 500;
}
.kr-hotlist .go-more {
  float: right;
  font-size: 13px;
  color: #787878;
  padding-right: 10px;
}
a {
  text-decoration: none;
}
.kr-hotlist .hotlist-main {
  padding-top: 2px;
  background-color: #fff;
}
.kr-hotlist .hotlist-main .hotlist-item-toptwo {
  overflow: hidden;
  margin-bottom: 10px;
  width: 300px;
  height: 200px;
  position: relative;
  border-radius: 2px;
}
.kr-hotlist .hotlist-main .hotlist-item-toptwo .hotlist-item-toptwo-pic {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.kr-hotlist .hotlist-main .hotlist-item-toptwo .hotlist-item-toptwo-pic img {
  height: 100%;
}
.scaleBig {
  -webkit-transition: all 1s;
  transition: all 1s;
}
.kr-hotlist .hotlist-main .hotlist-item-toptwo .hotlist-item-toptwo-icon {
  padding: 19px 30px;
  position: absolute;
  top: 0;
  left: -10px;
  z-index: 1;
  background: url(//static.36krcdn.com/36kr-web/static/home.d1523964.png)
    no-repeat;
  background-size: 246px 1012px;
}
.kr-hotlist .hotlist-main .hotlist-item-toptwo .hotlist-item-toptwo-title {
  border-radius: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(13%, transparent),
    color-stop(97%, rgba(0, 0, 0, 0.65))
  );
  background-image: linear-gradient(
    -180deg,
    transparent 13%,
    rgba(0, 0, 0, 0.65) 97%
  );
}
.kr-hotlist .hotlist-main .hotlist-item-toptwo .hotlist-item-toptwo-title p {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: justify;
  padding-left: 10px;
  padding-right: 16px;
  max-height: 40px;
  position: absolute;
  bottom: 10px;
  overflow: hidden;
}
.ellipsis-2,
.ellipsis-3 {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ellipsis-2 {
  -webkit-line-clamp: 2;
}
.kr-hotlist .hotlist-main .hotlist-item-other {
  margin-top: 14px;
  border-radius: 2px;
  height: 70px;
}
.kr-hotlist .hotlist-main .hotlist-item-other .hotlist-item-other-pic {
  float: left;
  height: 100%;
  width: 105px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  margin-left: 10px;
}
.kr-hotlist .hotlist-main .hotlist-item-other .hotlist-item-other-pic a {
  overflow: hidden;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
}
.kr-hotlist .hotlist-main .hotlist-item-other .hotlist-item-other-pic a img {
  height: 100%;
}
.scaleBig {
  -webkit-transition: all 1s;
  transition: all 1s;
}
.kr-hotlist
  .hotlist-main
  .hotlist-item-other
  .hotlist-item-other-pic
  .hotlist-item-other-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  border-radius: 0.5px 0.5px 2px 0.5px;
  background-image: url(//static.36krcdn.com/36kr-web/static/home.d1523964.png);
  background-repeat: no-repeat;
  background-size: 246px 1012px;
}
.kr-hotlist .hotlist-main .hotlist-item-other .hotlist-item-other-info {
  height: 100%;
  position: relative;
  float: left;
  width: 185px;
}
.kr-hotlist
  .hotlist-main
  .hotlist-item-other
  .hotlist-item-other-info
  .hotlist-item-other-title {
  font-size: 13px;
  color: #262626;
  letter-spacing: 0;
  text-align: justify;
  line-height: 20px;
  max-height: 40px;
  overflow: hidden;
}
.kr-hotlist
  .hotlist-main
  .hotlist-item-other
  .hotlist-item-other-info
  .hotlist-item-other-time {
  font-size: 12px;
  color: #a7a7a7;
  letter-spacing: 0;
  text-align: justify;
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 18px;
}
.clearfloat:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}
.hot{
  display: inline-block;
  min-width: 20px;
  text-align: center;
  line-height: inherit;
  width: auto;
}
.hot1{
  background-color: #f54545;
}
.hot2{
  background-color: #ff8547;
}
.hot3{
  background-color: #ffac38;
}
.hot4-10{
  background-color: #8eb9f5;
}
.hot-active{
  display: inline-block;
  width: 12px;
  height: 15px;
  background: url(~@/assets/img/hot-active.png) no-repeat;
}
.hot-no-active{
 display: inline-block;
  width: 12px;
  height: 15px;
  background: url(~@/assets/img/hot-no-active.png) no-repeat;
}
ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0px;
}
.hotlist-main ul li{
  margin-top: 15px;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  font-family: "Microsoft YaHei","Helvetica Neue";
  width: 100%;
}
.hotlist-main ul li span:nth-child(3){
  float: right;
}
.hotlist-main ul li span:nth-child(2){
  display: inline-block;
  width: 77%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 17px;
  line-height: 17px;
  margin-left: 2px;
  cursor: pointer;
}
#hotListNews{
  margin-top: 25px;
}
#hot-title{
  font-size: 14px;
}
</style>
<template>
  <div class="newsflash-item">
    <a
      class="item-title"
      rel="noopener noreferrer"
      @click="exchangeId()"
    >{{title}}</a>
    <div class="item-other">
      <span class="time">{{time}}</span>
      <!-- <span>分享至</span>
      <div class="wechat">
        <div class="share-code">
          <img
            class="qrcode"
            src="https://gateway.36kr.com/api/mis/sns/share/qrCodeUrl?partner_id=web&amp;param.siteId=1&amp;param.platformId=2&amp;param.url=https://36kr.com/newsflashes/683238776799362"
            alt="36氪"
          />打开微信“扫一扫”，打开网页后点击屏幕右上角分享按钮
        </div>
      </div>
      <a
        class="weibo"
        href="http://v.t.sina.com.cn/share/share.php?appkey=595885820&amp;url=https://36kr.com/newsflashes/683238776799362&amp;title=比特大陆：强烈谴责詹克团疫情期间诉讼行为"
        target="_blank"
        rel="noopener noreferrer nofollow"
      ></a> -->
    </div>
    <div class="item-desc">
      <pre
        class="pre-item-des"
        v-html="content"
      >
                <!-- <a
    class="article-link-icon"
    href="https://weibo.com/3903535942/IFgcVCzvx?from=page_1006063903535942_profile&amp;wvr=6&amp;mod=weibotime"
    rel="noopener noreferrer nofollow"
    >原文链接</a> -->
            </pre>
    </div>
    <span style="color:#0084FF;font-size: 15px;cursor: pointer;" v-show="accessory   != '' && accessory   != null"  @click="see(accessory)" >查看附件</span>
    <a  :href="reference " target="_blank"><span   style="color:#0E86F6;cursor:pointer;font-size:13px">{{reference }}</span></a>
    <div class="item-image-box"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    reference:{
     type: String,
      required: true,
    },
    accessory:{
           type: String,
      required: true,
    }
  },
  methods:{
        ///附件
 see(answerFiles){
 
     window.location.href =this.api.LoginURL.concat( answerFiles);
  },
    exchangeId(){
      this.$emit('exchangeId')
    }
  }
};
</script>

<style>
.newsflash-item {
  width: 100%;
}
.newsflash-item .item-title {
  display: block;
  color: #262626;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  letter-spacing: 0;
  text-align: justify;
  line-height: 42px;
  margin-bottom: 20px;
  font-weight: 700;
}
a {
  text-decoration: none;
}
.newsflash-item .item-other {
  height: 20px;
  line-height: 20px;
  margin: 20px 0;
  font-size: 12px;
  color: #a7a7a7;
}

.newsflash-item .item-other > * {
  float: left;
  margin-right: 8px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.newsflash-item .item-other .time {
  margin-right: 20px;
}
.newsflash-item .item-other .wechat {
  position: relative;
  height: 20px;
  width: 20px;
  background: url(//static.36krcdn.com/36kr-web/static/wechat-pray.28ebe3d1.png)
    no-repeat 50%;
  background-size: cover;
  cursor: pointer;
}
.newsflash-item .item-other .wechat .share-code {
  visibility: hidden;
  position: absolute;
  z-index: 1001;
  top: -90px;
  left: -20px;
  height: 80px;
  width: 240px;
  line-height: 20px;
  padding: 10px 10px 10px 80px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px #ddd;
  font-size: 13px;
  color: #262626;
  -webkit-transition: visibility 0.2s;
  transition: visibility 0.2s;
}
.newsflash-item .item-other .wechat .share-code .qrcode {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
}
.newsflash-item .item-other .weibo {
  height: 20px;
  width: 20px;
  background: url(//static.36krcdn.com/36kr-web/static/weibo-pray.55864b4a.png)
    no-repeat 50%;
  background-size: cover;
  cursor: pointer;
}
.newsflash-item .item-desc {
  font-size: 16px;
  color: #262626;
  letter-spacing: 0;
  text-align: justify;
  line-height: 30px;
}
.newsflash-item .item-desc .pre-item-des {
  white-space: pre-wrap;
}
.newsflash-item .item-desc a.article-link-icon {
  color: #4d7fd0;
  display: inline-block;
  cursor: pointer;
  height: 11px;
}
.newsflash-item .item-desc a.article-link-icon:before {
  display: inline-block;
  margin: 0 5px;
  position: relative;
  top: 10px;
  width: 11px;
  height: 11px;
  content: " ";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR…xy5babq+9EKIaIFN8gfDX4s8Au2kgiLm1Gp7vqi/1/6d2B39nkmQJFxGAlAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 11px;
}
</style>
<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 导航 -->
    <div class="head">
      <div>
        <V-ArticleNav :navList="navList"></V-ArticleNav>
      </div>
    </div>
    <!-- 内容 -->
    <div class="center">
      <div class="content">
        <!-- 快讯详细信息 -->
        <div class="left">
          <div class="now-flash">
            <V-One
              :title="nowNewsFlash.title"
              :reference="nowNewsFlash.reference"
              :accessory="nowNewsFlash.accessory"
              :time="nowNewsFlash.time"
              :content="nowNewsFlash.content"
              :id="nowNewsFlash.id"
            ></V-One>
          </div>
          <!-- <div class="next-newsflash-item" v-if="nextNewsFlash.id!=-1" >
              <div>
                  <span><span>下</span>一篇</span>
              </div>
              <div class="modify-pad">
              <V-One @exchangeId="exchangeId" :title="nextNewsFlash.title" :time="nextNewsFlash.time" :content="nextNewsFlash.content" :id="nextNewsFlash.id"></V-One>
              </div>
          </div>-->
        </div>
        <!-- 24小时热榜 -->
        <div class="right">
          <div>
            <V-Right :list="hotlist"></V-Right>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleNav from "@/components/article-nav/index.vue";
import One from "@/views/academic-headlines/common/news-flash-detail/one/index.vue";
import Right from "@/views/academic-headlines/common/news-flash-detail/right/index.vue";
import { QUERY } from "@/services/dao.js";
import { formatDate } from "@/js/common";
export default {
  data() {
    return {
      id: -1,
      navList: [{ word: "正文" }],
      nowNewsFlash: {
        title: "",
        time: "",
        content: "",
        id: -1,
        reference: "",
        accessory: ""
      },
      nextNewsFlash: {
        title: "",
        time: "",
        content: "",
        id: -1,
        reference: "",
        accessory: ""
      },
      hotlist: []
    };
  },
  created: function() {
    this.init();
  },
  watch: {
    // 监听url变化，刷新页面
    $route(to, from) {
      if (to.fullPath != from.fullPath) {
        this.init();
      }
    }
  },
  methods: {
    init() {
      this.id = Number(this.$route.query.id);
      this.lowerid = Number(this.$route.query.lowerid) || "";
      this.getNowNewsFlash();
      this.getNextNewsFlash();
      this.getHotList();
    },
    async getHotList() {
      let info = await QUERY(
        "post",
        "",
        "ComprehensiveArticleList_2( limit: 10) {    id    img    title   createTime  hotNum }"
      );
      this.hotlist.splice(0, this.hotlist.length);
      for (let i = 0; i < info.data.ComprehensiveArticleList_2.length; i++) {
        this.hotlist.push({
          id: info.data.ComprehensiveArticleList_2[i].id * 1,
          img: info.data.ComprehensiveArticleList_2[i].img,
          title: info.data.ComprehensiveArticleList_2[i].title,
          time: info.data.ComprehensiveArticleList_2[i].createTime,
          hotNum: info.data.ComprehensiveArticleList_2[i].hotNum
        });
      }
    },
    //显示本章内容
    async getNowNewsFlash() {
      let info = await QUERY(
        "post",
        "",
        '  News(limit: 1, where: {id: {_eq: "' +
          this.id +
          '"}}) {    id accessory   content reference   createTime    title  }'
      );
      this.nowNewsFlash.title = info.data.News[0].title;
      this.nowNewsFlash.time = formatDate(
        Date.parse(info.data.News[0].createTime)
      );
      // eslint-disable-next-line no-useless-escape
      this.nowNewsFlash.content = info.data.News[0].content.replace(
        /[\'\"\\\\]/g,
        ""
      );

      this.nowNewsFlash.reference = info.data.News[0].reference
        ? info.data.News[0].reference
        : "";
      this.nowNewsFlash.id = info.data.News[0].id * 1;
      this.nowNewsFlash.accessory = info.data.News[0].accessory
        ? info.data.News[0].accessory
        : ""; ///附件
    },
    //显示下一篇内容
    async getNextNewsFlash() {
      let info = await QUERY(
        "post",
        "",
        '  News( where: {id: {_eq: "' +
          this.lowerid +
          '"}}) {    id  accessory  content    createTime    title  }'
      );
      this.nextNewsFlash.title = info.data.News[0].title;
      this.nextNewsFlash.time = info.data.News[0].createTime;
      // eslint-disable-next-line no-useless-escape
      this.nextNewsFlash.content = info.data.News[0].content;
      this.nextNewsFlash.reference = info.data.News[0].reference;
      this.nextNewsFlash.id = info.data.News[0].id;
      this.nextNewsFlash.accessory = info.data.News[0].accessory; ///附件
    },
    // 点击下一篇快讯将内容显示到上面
    exchangeId() {
      let temp = this.nextNewsFlash.id;
      this.nextNewsFlash.id = this.nowNewsFlash.id;
      this.nowNewsFlash.id = temp;
      // console.log('交换： ',this.nowNewsFlash.id,this.nextNewsFlash.id)
    }
  },
  components: {
    "V-ArticleNav": ArticleNav,
    "V-One": One,
    "V-Right": Right
  }
};
</script>

<style scoped>
.container {
  text-align: center;
  background-color: #f7f7f7;
}
.container > .head {
  height: 100px;
  line-height: 100px;
  background-color: #e8eaeb;
}
.container > .center {
  margin-top: 30px;
  width: 100%;
  padding-bottom: 30px;
}
.container > .center > .content > .left {
  padding: 30px;
  /* height: 1130px; */
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.container > .head > div,
.container > .center > .content {
  width: 1090px;
  display: inline-block;
  text-align: left;
  position: relative;
}
.container > .center > .content > .left {
  display: inline-block;
  width: 620px;
  background-color: #ffffff;
}
.container > .center > .content > .right {
  display: inline-block;
  padding: 10px 5px 15px 10px;
  vertical-align: top;
  width: 25%;
  margin-left: 30px;
  /* text-align: center; */
  background-color: #ffffff;
  /* height: 1190px; */
}
.container > .center > .content > .right > div {
  display: inline-block;
  width: 100%;
}
.container > .center > .content > .left > .now-flash {
  padding: 20px 0;
}
.next-newsflash-item {
  padding: 20px;
  border-top: 2px solid black;
  margin-top: 50px;
  border-left: 1px solid rgb(223, 217, 217);
  border-right: 1px solid rgb(223, 217, 217);
  border-bottom: 1px solid rgb(223, 217, 217);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.next-newsflash-item > div:first-of-type > span {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 36px;
}
.next-newsflash-item > div:first-of-type > span > span {
  display: inline-block;
  border-bottom: 2px solid rgb(155, 151, 151);
  padding-bottom: 10px;
}
.next-newsflash-item .modify-pad {
  box-sizing: border-box;
  /* padding-bottom: 20px; */
  height: 40px;
  overflow: hidden;
}
</style>
